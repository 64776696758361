header{
    
    @include media-breakpoint-down(sm) { 
        padding-left: 14px;
        padding-top: 14px;
        padding-bottom: 10px;
    }
    @include media-breakpoint-up(md) { 
        padding-left: 24px;
        padding-top: 24px;
        padding-bottom: 18px;
    }

    .logo{

        @include media-breakpoint-down(sm) {
            height: 22px;
        }

        @include media-breakpoint-up(md) {
            height: 36px;
        }
    }

    .form-name{
        font-family: fs-joey-medium, lato, sans-serif;
        position: relative;
        width: 90%;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            left: 24px;
            top: -1px;
        }

        @include media-breakpoint-up(md) {
            font-size: 25px;
            left: 38px;
            top: -2px;
        }
    }

    
}