
// Custom variables here


//@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);


@font-face {
    font-family: "fs-joey-medium";
    src: url("../../assets/flex/fonts/FSJoeyWeb-Medium.woff") format('woff');
}


$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;



// Colors from styleguide
$orange: #F36D21;
$teal: #006168;
$gray: #323E48;
$light-orange: #FCC196;

$white:    #fff;
$gray-100: lighten($gray, 75%);
$gray-200: lighten($gray, 70%);
$gray-300: lighten($gray, 60%);
$gray-400: lighten($gray, 50%);
$gray-500: lighten($gray, 40%);
$gray-600: lighten($gray, 30%);
$gray-700: lighten($gray, 20%);
$gray-800: lighten($gray, 10%);
$gray-900: $gray;

// Colors from website
$blue: #006168;
$light-blue: #E6F5F7;
$yellow: #EEC666;
$red: #D6482B;
$green: #32A176;
$light-green: #7BBB58;

// Overrides
$primary:       $orange;
$secondary:     $gray;
$success:       $teal;
$info:          #169599;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray;


$input-btn-padding-y: 0.7rem;
$input-btn-padding-x: 1.8rem;

$input-btn-padding-y-sm: 0.7rem;
$input-btn-padding-x-sm: 1.8rem;

$input-btn-padding-y-lg: 0.7rem;
$input-btn-padding-x-lg: 1.8rem;

$border-radius:               .2rem;
$border-radius-lg:            .2rem;
$border-radius-sm:            .2rem;


// Component variables
$progress-bar-color: $teal;
$footer-strip-color: $teal;

$headings-font-weight: 700;


// Header

$header-padding-top-desktop: 30px;
$header-padding-bottom-desktop: 24px;
$header-text-top-desktop: -4px;
$header-text-font-size-desktop: 30px;
$header-text-font-weight-desktop: 500;
$header-logo-margin-left-desktop: 50px;
$header-logo-width-desktop: 350px;
$header-logo-height-desktop: inherit;

$header-padding-top-mobile: 12px;
$header-padding-bottom-mobile: 6px;
$header-text-top-mobile: -2px;
$header-text-font-size-mobile: 20px;
$header-text-font-weight-mobile: 500;
$header-margin-left-mobile: -21px;
$header-logo-width-mobile: 200px;
$header-logo-height-mobile: inherit;

$header-text-font-family: fs-joey-medium, lato, sans-serif;
$header-text-color: $secondary;
$header-text-letter-spacing: -0.05em;


@import '../common.scss';
@import './flex-header.scss';
